@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
.main {
  position: relative;
  display: grid;
  overflow: hidden;
  padding: $spacing-lg $spacing-sm;
  background-color: $background-surface;
  place-items: center;

  @include screen-size('medium') {
    padding: $spacing-xl $spacing-md;
  }

  &.hide {
    display: none;
  }
}

.wrapper {
  position: relative;
  z-index: 10;
  display: grid;
  max-width: 71rem;
  gap: $spacing-lg;
}

.trustpilot {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacing-lg 0;
  border-top: 1px solid $stroke;
  border-bottom: 1px solid $stroke;
}

.navigation {
  display: flex;
  width: 100%;
  gap: $spacing-sm;

  @include screen-size('extra-small') {
    width: auto;
    gap: $spacing-md;
  }

  .list {
    flex-grow: 1;
    justify-content: stretch;
    gap: $spacing-sm;

    @include screen-size('small') {
      flex-grow: 0;
    }

    @include screen-size('medium') {
      min-width: 17rem;
    }
  }

  a.link,
  button.link {
    @include text-lg;
    @include text-bold;

    display: inline-block;
    padding: 0;
    color: $label-primary-light;

    &:hover {
      text-decoration: none;
    }

    @include screen-size('medium') {
      @include display-sm;
    }
  }
}

.social {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  gap: $spacing-md;

  .accounts {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    padding: 0;
    margin: 0;
    gap: $spacing-sm;
    list-style: none;

    li {
      display: inline-flex;
      align-items: center;
    }

    .facebook {
      filter: $facebook-filter;
    }

    .instagram {
      filter: $instagram-filter;
    }

    .x {
      filter: $x-filter;
    }

    .youtube {
      filter: $youtube-filter;
    }

    .linkedin {
      filter: $linkedin-filter;
    }

    .tiktok {
      filter: $tiktok-filter;
    }
  }
}

.stacked {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: $spacing-lg;
}

.list {
  display: grid;
  padding: 0;
  margin: 0;
  list-style: none;
  place-content: flex-start;
  text-align: left;
}

.popular {
  display: grid;
  gap: $spacing-sm;

  p {
    @include text-md;
    @include text-normal;
  }
}

.legal {
  display: flex;
  flex-wrap: wrap;
  padding-top: $spacing-lg;
  border-top: 0.125rem solid $stroke;
  gap: $spacing-lg;

  .list {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-sm;
  }

  @include screen-size('medium') {
    align-items: center;
    justify-content: space-between;
  }
}

.popular {
  .list {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-xs;
  }
}

.affiliates {
  align-items: stretch;
  opacity: 0.6;

  &.list {
    flex-wrap: nowrap;
  }

  li,
  a {
    display: inline-flex;
    align-items: flex-end;
  }
}

.additional {
  display: grid;
  gap: $spacing-sm;

  p {
    @include text-xs;
    @include text-normal;

    color: $label-supporting-light;
  }
}

.ring {
  position: absolute;
  right: 0;
  bottom: 0;
  display: none;
  width: 25rem;
  height: 25rem;
  border: 7.5rem solid $yellow;
  border-radius: 25rem;
  content: '';
  opacity: 5;
  transform: translate(50%, 50%);

  @include screen-size('medium') {
    display: block;
  }
}

@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
.main {
  &[data-input-type='tel'] {
    display: grid;
    grid-template-areas:
      'label label'
      'input input'
      'hint hint';
    grid-template-columns: auto 1fr;

    // targets phone number prefix found in <UserProfileBuilder />
    &:has(div > svg) {
      grid-template-areas:
        'label label'
        'mask input'
        'hint hint';
    }
  }

  &[data-disabled='true'][data-visual='false'] {
    .box {
      background-color: $fill-disabled-light;
    }

    .box,
    .input,
    .input::placeholder {
      color: $label-disabled-light;
    }

    .label,
    .foot {
      color: $label-disabled-light;
    }
  }
}

.label {
  @include text-sm;
  @include text-bold;

  padding: 0 0 0.25rem;
  margin: 0;
  color: $label-supporting-light;
  grid-area: label;
  transition: color 0.2s ease-in-out;
}

.box {
  display: flex;
  align-items: center;
  padding: 0.9375rem 1.25rem;
  border-radius: 0.5rem;
  background-color: $fill-inactive-light;
  gap: 0.5rem;
  grid-area: input;
  transition: background-color 0.2s ease-in-out;

  &[data-error='true'] {
    border: 1px solid $red;
  }

  &:hover {
    cursor: text;
  }

  &.with-button {
    padding: 0.625rem 0.625rem 0.625rem 1.25rem;

    button {
      white-space: nowrap;
    }
  }

  &:focus-within,
  &:hover {
    background-color: $fill-supporting-light;
  }
}

.input {
  @include text-md;
  @include text-normal;

  width: 100%;
  flex-grow: 1;
  padding: 0;
  border: 0 none;
  background: transparent;

  &:focus-within,
  &:focus-visible,
  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: $label-supporting-light;
    transition: color 0.2s ease-in-out;
  }
}

.foot {
  @include text-sm;
  @include text-normal;

  display: flex;
  align-items: center;
  padding: 0.25rem 0 0;
  color: $label-inactive-light;
  grid-column: 1 / -1;
  transition: color 0.2s ease-in-out;

  &:hover {
    cursor: default;
  }

  &[data-error='true'] {
    color: $red;
  }
}

.validation {
  display: grid;
  padding: 0 $spacing-xs;

  .tests {
    display: grid;
    padding: 0;
    margin: 0;
    gap: $spacing-xs;
  }

  .test {
    @include text-xs;
    @include text-normal;

    display: flex;
    align-items: flex-start;
    color: $label-supporting-light;
    gap: $spacing-xxs;

    &[data-status='pass'] {
      color: $background-success-light;
    }

    &[data-has-been-validated='true'] {
      &[data-status='fail'] {
        color: $error;
      }
    }
  }
}

.icon {
  &-default,
  &-fail {
    color: $label-supporting-light;
  }

  &-pass {
    color: $background-success-light;
  }
}

.password {
  display: grid;
  gap: $spacing-xs;
}

@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
.main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: $spacing-md $spacing-sm;
  border-radius: $spacing-sm;
  margin-bottom: $spacing-xs;
  background-color: $pale-yellow;
  gap: $spacing-sm;

  &[data-is-inline='true'] {
    max-width: 25rem;
    padding: 0;
    background-color: revert;

    .form-wrapper {
      width: 100%;
    }
  }

  @include screen-size('medium') {
    &[data-is-inline='false'] {
      align-items: center;
      justify-content: space-between;
      padding: $spacing-lg $spacing-md;
    }

    .notification {
      display: inline-flex;
      flex: 1 0 100%;
    }
  }
}

.notification {
  @include text-md;
  @include text-normal;
  display: inline-flex;
  flex-grow: 1;
  align-items: center;
  padding: $spacing-xs;
  border-radius: $spacing-xs;
  gap: $spacing-xs;

  &[data-state='complete'] {
    border: 1px solid $green;

    .notification--icon {
      color: $green;
    }
  }

  &[data-state='error'] {
    border: 1px solid $red;

    .notification--icon {
      color: $red;
    }
  }
}

.form {
  display: grid;
  gap: $spacing-sm;
}

.title {
  @include text-xl;
  @include text-bold;
  margin: 0;

  &.large {
    @include display-xs;
  }
}

.paragraph {
  @include text-md;
  @include text-normal;
}
